var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            id: _vm.idmodal,
            title: _vm.title,
            centered: _vm.centered,
            "dialog-style": _vm.dialogstyle,
            "mask-closable": _vm.maskClosable,
            width: _vm.width,
            showmodal: _vm.showmodal,
            "on-ok": "handleOk",
            closable: false,
            keyboard: false,
            "force-render": _vm.forceRender
          },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("template", { slot: "footer" }, [_vm._t("footermodal")], 2),
          _vm._t("contentmodal")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }