
























import Vue from "vue";
export default Vue.extend({
  props: [
    "title",
    "databind",
    "loading",
    "centered",
    "dialogstyle",
    "maskClosable",
    "width",
    "showmodal",
    "idmodal",
    "forceRender",
  ],
  data() {
    return {
      data:null,
      visible: false,
    };
  },
  watch: {
    showmodal: function (e) {
      if (e) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    },
  },
});
